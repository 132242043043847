import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { site } from "../data/site.data";

export const Footer = (props) => {
  return (
    <MDBFooter color="indigo" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="6">
            <h5 className="title">{site.data.companyName}</h5>
            <p>{site.data.companySubtitle}</p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a href="https://www.grynn.ch"> {site.data.companyName}</a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};
