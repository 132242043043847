export const site = {
  data: {
    companyName: "Grynn GmbH",
    companySubtitle: "First ERPNext partner in Europe",
    heading: "Our Pricing Plans",
    homeUrl: "https://www.grynn.ch",
    subTitle:
      "The servers for free trial are in Germany. If you wish to run a production site in Switzerland, kindly contact us for the Premium Plan.",
    plans: [
      {
        title: "Free Trial",
        icon: "home",
        price: "0",
        currency: "CHF",
        iconColorClass: "indigo-text",
        text: "No credit card is required, Trial & usage limited to 90 days.",
        actionButtonText: "Create Free Trial Instance",
        actionButtonLink: "/create-instance",
        suggestedPlan: false,
      },
      {
        title: "Swiss Plan",
        icon: "users",
        price: "35 / month / user",
        currency: "CHF",
        iconColorClass: "light-blue-text",
        text: "Daily backups and recovery. L2 support via email.",
        actionButtonText: "Contact Us",
        actionButtonLink: "/create-instance?contact=true",
        suggestedPlan: true,
      },
      {
        title: "Swiss Plan with L1 Support",
        icon: "users",
        price: "70 / month / user",
        currency: "CHF",
        iconColorClass: "light-blue-text",
        text: "Included L1 support via email and daily backups",
        actionButtonText: "Contact Us",
        actionButtonLink: "/create-instance?contact=true",
        suggestedPlan: false,
      },
    ],
    qna: [
          {
              question: "Wie aktualisiere ich von einer kostenlosen Version auf eine kostenpflichtige Version?",
              answer: "Sie können uns kontaktieren oder uns an support@grynn.ch schreiben, um Ihre kostenlose Version zu aktualisieren. Wir answer normalerweise innerhalb von 1 Werktag!",
          },
          {
              question: "Was ist das Probeangebot?",
              answer: "Der Trial-Server dient Ihnen zum Testen von ERPNext in einer Demo-Version, bei der alles bereits eingerichtet ist. So können Sie alle Funktionen aus erster Hand sehen.",
          },
          {
              question: "Was passiert nach Ablauf der Probezeit?",
              answer: "Wenn der Testzeitraum von 90 Tagen abgelaufen ist, archivieren wir die Site, die Sie erhalten haben. Wenn Sie zu einer kostenpflichtigen Version wechseln möchten, können Sie eine \"frische\" Site erhalten oder wir können Ihnen helfen, sie aus Ihrem Archiv in den gewünschten Plan / die gewünschte Cloud wiederherzustellen.",
          },
          {
              question: "Was ist L1-Support?",
              answer: "L1-Support bedeutet Hilfe bei der Funktionalität des Produkts. In diesem Fall helfen wir Ihnen bei der Klärung der Rechnungserstellung oder der Bedienung der Software.",
          },
          {
              question: "Was ist L2-Unterstützung?",
              answer: "Wenn wir Ihnen L2-Support anbieten, meinen wir die Überwachung des Servers auf Leistung oder Abstürze. Server werden rund um die Uhr auf Sicherheit, Leistung und im Allgemeinen überwacht, um sie in Betrieb zu halten. Wenn Sie Probleme mit langsamer Leistung oder Nichtverfügbarkeit haben, öffnen Sie bitte ein Ticket, indem Sie uns unter support@grynn.ch kontaktieren. Normalerweise antworten wir innerhalb von 4-6 Stunden.",
          },
          {
              question: "Können wir Hosting auf Azure / GCP / AWS bekommen?",
              answer: "Ja, wir können es in jeder Cloud Ihrer Wahl installieren.",
          },
          {
              question: "Unterstützen Sie eine Unternehmensbenutzerbasis wie 100 / 1000+ Benutzer?",
              answer: "Ja, wir haben eine Partnerschaft mit Frappe, um große Nutzerkreise zu unterstützen.",
          }
    ],
    validations: [
      {
        icon: "fa-server",
        value: "Trial servers are in ISO certified in Frankfurt / Germany. Production server will be as per your data center requirements.",
      },
      {
        icon: "fa-shield-alt",
        value: "Free SSL certificates For all customers.",
      },
      {
        icon: "fa-passport",
        value: "We Here at Grynn strictly follow GDPR Compliance. If you wish to contact us kindly email us at support@grynn.ch",
      },
    ],
  },
};
