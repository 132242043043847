import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { site } from "../data/site.data";

export const Header = (props) => {
  return (
    <MDBFooter color="indigo" className="font-small  ">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol>
            <a href={site.data.homeUrl}>
              <h5 className="title">Home</h5>{" "}
            </a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
};
